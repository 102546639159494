//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-532:_4840,_4788,_7708,_5208,_9576,_5900,_1132,_3744;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-532')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-532', "_4840,_4788,_7708,_5208,_9576,_5900,_1132,_3744");
        }
      }catch (ex) {
        console.error(ex);
      }